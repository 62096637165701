import router from '../../../router/index'
import store from '../../../store/index'
import { DateTime } from 'luxon'

const tableRender = (item) => {
  const tableData = []
  const invLength = parseInt(router.history.current.query.invLen)
  const pvLength = parseInt(router.history.current.query.pvLen)

  let factory = router.history.current.query.siteName || ''

  const isDemo = factory.includes('展示案場')

  if (isDemo) {
    const demoIndex = factory.split(' ')[1] - 1
    factory = store.state.factoryInfo[demoIndex].factory
  }

  const invSN = store.state.factoryInfo.find((x) => x.factory === factory)?.invSN || []

  let V = {}

  if (invLength) {
    for (let i = 0; i < invLength; i++) {
      if (item.Vrs || item.Vst || item.Vrt) {
        V.ab = item.Vrs?.[i]
        V.bc = item.Vst?.[i]
        V.ca = item.Vrt?.[i]
      } else if (item.Vrn || item.Vsn || item.Vtn) {
        V.ab = item.Vrn?.[i]
        V.bc = item.Vsn?.[i]
        V.ca = item.Vtn?.[i]
      }

      const data = {
        'INV 名稱': `INV${i + 1}${invSN[i] ? ` - ${invSN[i]}` : ''}`,
        '累積發電量 (kWh)': item.AC_kWh?.[i],
        '今日發電量 (kWh)': item.E_today?.[i],
        '直流發電功率 (kW)': item.dcp?.[i],
        '交流發電功率 (kW)': item.acp?.[i],
        'INV效率 (%)': item.Eff?.[i] ? item.Eff[i] * 100 : null,
        '頻率(Hz)': item.freq?.[i],
        'Vab 電壓 (V)': V.ab,
        'Vbc 電壓 (V)': V.bc,
        'Vca 電壓 (V)': V.ca,
        'A相電流 (A)': item.Rc?.[i],
        'B相電流 (A)': item.Sc?.[i],
        'C相電流 (A)': item.Tc?.[i],
        '連線狀態':
          DateTime.fromSeconds(Number(item.SYSTIME)).diff(
            DateTime.local(),
            'minutes'
          ).minutes <= -30
            ? 1
            : item.ErrorMessage?.inv?.[i],
        '_cellVariants': {}
        // _cellVariants: { '累積發電量 (kWh)': 'danger', '今日發電量 (kWh)': 'danger' }
      }
      // if (item.mppt_a && item.mppt_v) {
      for (let j = 0; j < pvLength; j++) {
        let a = `PV${j + 1}電流 (A)`
        let v = `PV${j + 1}電壓 (V)`
        if (item.mppt_v || item.mppt_a) {
          const Obj = {
            [v]: item.mppt_v?.[i]?.[j],
            [a]: item.mppt_a?.[i]?.[j]
          }
          Object.assign(data, Obj)
        } else if (item.pv_v || item.pv_a) {
          const Obj = {
            [v]: item.pv_v?.[i]?.[j],
            [a]: item.pv_a?.[i]?.[j]
          }
          Object.assign(data, Obj)
        }
      }
      tableData.push(data)
    }
  }

  tableData.forEach((x) => {
    if (x['交流發電功率 (kW)'] > 0) {
      const arr = []
      for (const [key, value] of Object.entries(x)) {
        if (value === 0 && key !== '連線狀態') {
          arr.push(key)
        }
      }
      arr.forEach((y) => {
        x._cellVariants[y] = 'danger'
      })
    }
  })
  return tableData
}

export default tableRender
