import router from '../../router/index'
import axios from 'axios'
import {
  getRecentThirtyDaysApi,
  getHoursApi,
  getDaysApi,
  getMonthApi,
  getYearApi,
  getInvsApi
} from '../../api'
import { dbDataHandle } from '../function/common/index'
import mainStore from '../index'

const state = {
  groupNumber: '',
  siteInfoDetailDownloadData: [],
  siteInfoDetailDownloadField: [],
  uluru: { lat: 22.6392702, lng: 120.377754 },
  weatherLonAndLat: {
    lon: '',
    lat: ''
  },
  siteInfoValue: [['案場 APP 名稱', 'SiteMonitor.exe']],
  siteInfoDetailChartOptions: {
    chart: {
      height: 280,
      backgroundColor: 'transparent',
      alignTicks: false
      // marginLeft: 40,
      // marginRight: 30
    },
    title: '',
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false
    },
    legend: {
      // align: 'right',
      // layout: 'vertical',
      // verticalAlign: 'middle'
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      margin: 30,
      labelFormat: '{name} {tooltipOptions.valueSuffix}'
    },
    yAxis: [
      {
        title: {
          // text: '發電量 (kWh)'
          text: ''
        },
        labels: {
          enabled: false // 顯示刻度數字
        },
        gridLineWidth: 0 // 刻度橫線粗細，與刻度數字是獨立調整
      },
      {
        title: {
          // text: '日照量 (kWh/m2)'
          text: ''
        },
        labels: {
          enabled: false
        },
        gridLineWidth: 0
      },
      {
        title: {
          // text: '發電效率 (%)'
          text: ''
        },
        max: 100,
        min: 0,
        tickInterval: 50, // 單位間距
        labels: {
          enabled: false
        },
        gridLineWidth: 0
        // opposite: true
      },
      {
        title: {
          // text: '模組溫度 (°C)'
          text: ''
        },
        max: 80,
        min: 0,
        labels: {
          enabled: false
        },
        gridLineWidth: 0
        // opposite: true
      },
      {
        title: {
          // text: '發電收入 (NTD)'
          text: ''
        },
        labels: {
          enabled: false
        },
        gridLineWidth: 0
      }
    ],
    xAxis: [
      {
        categories: [],
        crosshair: true,
        // index: 0,
        isX: true,
        gridLineWidth: 0
      }
    ],
    plotOptions: {
      series: {
        // ALL series
        dataLabels: {
          enabled: true
        }
      }
      // column: { // ALL column
      //   dataLabels: {
      //     enabled: true
      //   }
      // },
      // spline: { // ALL spline
      //   dataLabels: {
      //     enabled: true,
      //     // format: '{x} \t {y} mm'
      //     format: '{y} %'
      //   }
      // }
    },
    series: [],
    lang: {
      noData: '現在沒有資料'
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030'
      }
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
              y: 0
            }
          }
        }
      ]
    }
  },
  voltages: {
    accVal: 0, // 累積發電量
    todayVal: 0 // 今日發電量
  },
  realTimeGrid: {
    IRR: 0, // 目前日照
    eToday: 0, // 今日日照
    pr: 0,
    peak: 0,
    lowCurrent: 0,
    highCurrent: 0,
    moduleTemp: 0
  },
  weatherData: {
    temp: 0,
    desc: '',
    iconUrl: ''
  },
  capacity: [],
  resError: false,
  resChoose: '',
  showPR: false, // 圖表顯示發電效率
  showRevenue: false // 圖表顯示發電收入
}

const mutations = {
  WEATHER (state, payload) {
    state.weatherData.temp = payload.main.temp
    state.weatherData.desc = payload.weather[0].description
    state.weatherData.iconUrl = `${payload.isHttps}://openweathermap.org/img/wn/${payload.weather[0].icon}@2x.png`
    // state.weatherData.iconUrl = `${payload.isHttps}://openweathermap.org/img/w/${payload.weather[0].icon}.png`
  },
  CLEAR_CHART_SERIES (state) {
    // chart margin
    state.siteInfoDetailChartOptions.chart.marginRight = 30
    state.siteInfoDetailChartOptions.chart.marginLeft = 30

    state.siteInfoDetailChartOptions.xAxis[0].categories = []
    state.siteInfoDetailChartOptions.series = [
      {
        data: [],
        type: 'column',
        name: '發電量',
        yAxis: 0,
        color: '#7cb5ec',
        tooltip: {
          valueSuffix: ' (kWh)'
        }
        // showInLegend: false // 不顯示圖例
      },
      {
        data: [],
        type: 'line',
        name: '日照量',
        yAxis: 1,
        color: '#434348',
        tooltip: {
          valueSuffix: ' (kWh/m2)'
        }
      },
      {
        data: [],
        type: 'spline',
        dataLabels: {
          format: '{y} %'
        },
        name: '發電效率',
        yAxis: 2,
        color: '#90ed7d',
        tooltip: {
          valueSuffix: ' (%)'
        },
        showInLegend: true,
        dashStyle: 'shortdashdotdot',
        clip: false
      },
      {
        data: [],
        type: 'spline',
        dataLabels: {
          // format: '{y} °C'
        },
        name: '模組溫度',
        yAxis: 3,
        color: '#f7a35c',
        tooltip: {
          valueSuffix: ' (°C)'
        },
        dashStyle: 'shortdot'
      },
      {
        data: [],
        type: 'line',
        name: '發電收入',
        yAxis: 4,
        color: '#8085e9',
        tooltip: {
          valueSuffix: ' (NTD)'
        }
      }
    ]
    if (!state.showPR) {
      state.siteInfoDetailChartOptions.series[2].showInLegend = false
    }
    if (!state.showRevenue) {
      state.siteInfoDetailChartOptions.series[4].showInLegend = false
    }
    state.siteInfoDetailChartOptions.series.forEach((x) => {
      x.data = []
    })
  },
  SET_YMAX (state, payload) {
    if (payload) {
      const payloadData = payload.data

      let acpYmax =
        Math.max(...payloadData.map((p) => (p.acp ? p.acp.all || 0 : 0))) * 1.3
      state.siteInfoDetailChartOptions.yAxis[0].max = acpYmax

      let irrArray = []
      payloadData.forEach((item) => {
        const IRR =
          typeof item.IRR === 'object' && item.IRR !== null
            ? dbDataHandle(item.IRR.avg)
            : dbDataHandle(item.IRR)
        irrArray.push(IRR)
      })
      const irrDataMax = Math.max(...irrArray)
      let irrYmax = irrDataMax > 80 ? irrDataMax * 1.2 : 80 // 同模組溫度 max
      state.siteInfoDetailChartOptions.yAxis[1].max = irrYmax
    }
  },
  SET_YMAX_ACPALL (state, payload) {
    const payloadData = payload.data

    let acpYmax =
      Math.max(...payloadData.map((p) => (p.acp ? p.acp.all || 0 : 0))) * 1.3
    state.siteInfoDetailChartOptions.yAxis[0].max = acpYmax

    let irrArray = []
    payloadData.forEach((item) => {
      const IRR =
        typeof item.IRR === 'object' && item.IRR !== null
          ? dbDataHandle(item.IRR.avg)
          : dbDataHandle(item.IRR)
      irrArray.push(IRR)
    })
    const irrDataMax = Math.max(...irrArray)
    let irrYmax = irrDataMax > 80 ? irrDataMax * 1.2 : 80 // 同模組溫度 max
    state.siteInfoDetailChartOptions.yAxis[1].max = irrYmax
  },
  GET_CHART_BY_RECENT_30_DAYS (state, payload) {
    if (!payload?.success) {
      state.resChoose = '最近30天'
      state.resError = true
    } else {
      state.resError = false
      state.showPR = true
      state.showRevenue = false

      // get price from store
      const info = mainStore.state.factoryInfo.filter(
        (f) => f.factoryId === router.history.current.query.fid
      )
      const unitPrice = info[0]?.informations.unitPrice
      let price = 0

      if (unitPrice && unitPrice !== '-') {
        price = unitPrice * 1
        state.showRevenue = true
      }

      // 清空
      this.commit('siteInfoDetail/CLEAR_CHART_SERIES')
      // set yMax
      this.commit('siteInfoDetail/SET_YMAX', payload)

      // set chart margin (讓 x 軸日期不會被擠壓)
      state.siteInfoDetailChartOptions.chart.marginLeft = 40
      state.siteInfoDetailChartOptions.chart.marginRight = 30

      // data push
      payload.data = payload.data.sort(function (a, b) {
        return a.date > b.date
      })
      payload.data.forEach((item) => {
        if (
          item.acp !== undefined ||
          item.IRR !== undefined ||
          item.pr !== undefined ||
          item.PVTemp !== undefined
        ) {
          const time = item.date
          const acp = item.acp !== null ? dbDataHandle(item.acp.all) : 0
          const IRR =
            typeof item.IRR === 'object' && item.IRR !== null
              ? dbDataHandle(item.IRR.avg)
              : dbDataHandle(item.IRR)
          // const PR = calculatePR(this.state.capacity, item.acp, IRR)
          const PR =
            item.pr !== null
              ? dbDataHandle(Math.floor(item.pr.all * 100))
              : null
          const PVTemp =
            item.PVTemp !== null ? dbDataHandle(item.PVTemp) : null
          const revenue = dbDataHandle(acp * price, 0)
          state.siteInfoDetailChartOptions.xAxis[0].categories.push(time)
          state.siteInfoDetailChartOptions.series[0].data.push(acp)
          state.siteInfoDetailChartOptions.series[1].data.push(IRR)
          state.siteInfoDetailChartOptions.series[2].data.push(PR)
          state.siteInfoDetailChartOptions.series[3].data.push(PVTemp)
          if (state.showRevenue) {
            state.siteInfoDetailChartOptions.series[4].data.push(revenue)
          }
        }
      })

      // revenue Ymax
      const revenueDataMax = Math.max(
        ...state.siteInfoDetailChartOptions.series[4].data
      )
      state.siteInfoDetailChartOptions.yAxis[4].max =
        revenueDataMax === 0 ? 100 : null // auto calculated

      this.commit(
        'siteInfoDetail/GET_DOWNLOAD_DATA',
        state.siteInfoDetailChartOptions
      )
    }
  },
  GET_CHART_BY_HOUR (state, payload) {
    if (!payload?.success) {
      state.resChoose = '時歷史'
      state.resError = true
    } else {
      state.resError = false
      state.showPR = false

      // get price from store
      const info = mainStore.state.factoryInfo.filter(
        (f) => f.factoryId === router.history.current.query.fid
      )
      const unitPrice = info[0]?.informations.unitPrice
      let price = 0

      if (unitPrice && unitPrice !== '-') {
        price = unitPrice * 1
        state.showRevenue = true
      }

      // 清空
      this.commit('siteInfoDetail/CLEAR_CHART_SERIES')
      // set yMax
      this.commit('siteInfoDetail/SET_YMAX_ACPALL', payload)

      // data push
      payload.data = payload.data.sort(function (a, b) {
        return a.hhmm > b.hhmm ? 1 : -1
      })
      payload.data.forEach((item) => {
        if (parseInt(item.hhmm.substr(0, 2)) >= 5) {
          if (
            item.acp !== undefined ||
            item.IRR !== undefined ||
            item.pr !== undefined ||
            item.PVTemp !== undefined
          ) {
            const time = item.hhmm
            const acp = item.acp !== null ? dbDataHandle(item.acp.all) : null // 發電量
            const IRR =
              typeof item.IRR === 'object' && item.IRR !== null
                ? dbDataHandle(item.IRR.avg)
                : dbDataHandle(item.IRR)
            const PVTemp = item.PVTemp ? dbDataHandle(item.PVTemp) : null
            // const PR = item.pr !== null ? dbDataHandle(Math.floor(item.pr.all * 100)) : null
            const revenue = dbDataHandle(acp * price, 0)
            state.siteInfoDetailChartOptions.xAxis[0].categories.push(time)
            state.siteInfoDetailChartOptions.series[0].data.push(acp)
            state.siteInfoDetailChartOptions.series[1].data.push(IRR)
            // state.siteInfoDetailChartOptions.series[2].data.push(PR)
            // 暫時filter moduleTemp
            state.siteInfoDetailChartOptions.series[3].data.push(PVTemp)
            if (state.showRevenue) {
              state.siteInfoDetailChartOptions.series[4].data.push(revenue)
            }
          }
        }
      })

      // IRR Ymax
      const irrDataMax = Math.max(
        ...state.siteInfoDetailChartOptions.series[1].data
      )
      state.siteInfoDetailChartOptions.yAxis[1].max =
        irrDataMax === 0 ? 100 : irrDataMax * 5

      // revenue Ymax
      const revenueDataMax = Math.max(
        ...state.siteInfoDetailChartOptions.series[4].data
      )
      state.siteInfoDetailChartOptions.yAxis[4].max =
        revenueDataMax === 0 ? 100 : null // auto calculated

      this.commit(
        'siteInfoDetail/GET_DOWNLOAD_DATA',
        state.siteInfoDetailChartOptions
      )
    }
  },
  GET_CHART_BY_DAY (state, payload) {
    if (!payload?.success) {
      state.resChoose = '日歷史'
      state.resError = true
    } else {
      state.resError = false
      state.showPR = true

      // get price from store
      const info = mainStore.state.factoryInfo.filter(
        (f) => f.factoryId === router.history.current.query.fid
      )
      const unitPrice = info[0]?.informations.unitPrice
      let price = 0

      if (unitPrice && unitPrice !== '-') {
        price = unitPrice * 1
        state.showRevenue = true
      }

      // console.log('payload in GET_CHART_BY_DAY', payload)
      // 清空
      this.commit('siteInfoDetail/CLEAR_CHART_SERIES')
      // set yMax
      this.commit('siteInfoDetail/SET_YMAX_ACPALL', payload)
      // this.commit('siteInfoDetail/SET_YMAX', payload)

      // data push
      payload.data = payload.data.sort(function (a, b) {
        return a.date > b.date ? 1 : -1
      })
      payload.data.forEach((item) => {
        if (
          item.acp !== undefined ||
          item.IRR !== undefined ||
          item.pr !== undefined ||
          item.PVTemp !== undefined
        ) {
          const time = item.date
          const acp = item.acp !== null ? dbDataHandle(item.acp.all) : null // 發電量
          const IRR =
            typeof item.IRR === 'object' && item.IRR !== null
              ? dbDataHandle(item.IRR.avg)
              : dbDataHandle(item.IRR)
          const PR =
            item.pr !== null
              ? dbDataHandle(Math.floor(item.pr.all * 100))
              : null
          const PVTemp = item.PVTemp > 0 ? dbDataHandle(item.PVTemp) : null
          const revenue = dbDataHandle(acp * price, 0)
          state.siteInfoDetailChartOptions.xAxis[0].categories.push(time)
          state.siteInfoDetailChartOptions.series[0].data.push(acp)
          state.siteInfoDetailChartOptions.series[1].data.push(IRR)
          state.siteInfoDetailChartOptions.series[2].data.push(PR)
          state.siteInfoDetailChartOptions.series[3].data.push(PVTemp)
          if (state.showRevenue) {
            state.siteInfoDetailChartOptions.series[4].data.push(revenue)
          }
        }
      })

      // revenue Ymax
      const revenueDataMax = Math.max(
        ...state.siteInfoDetailChartOptions.series[4].data
      )
      state.siteInfoDetailChartOptions.yAxis[4].max =
        revenueDataMax === 0 ? 100 : null // auto calculated

      this.commit(
        'siteInfoDetail/GET_DOWNLOAD_DATA',
        state.siteInfoDetailChartOptions
      )
    }
  },
  GET_CHART_BY_MONTH (state, payload) {
    if (!payload?.success) {
      state.resChoose = '月歷史'
      state.resError = true
    } else {
      state.resError = false
      state.showPR = true

      // get price from store
      const info = mainStore.state.factoryInfo.filter(
        (f) => f.factoryId === router.history.current.query.fid
      )
      const unitPrice = info[0]?.informations.unitPrice
      let price = 0

      if (unitPrice && unitPrice !== '-') {
        price = unitPrice * 1
        state.showRevenue = true
      }

      // console.log('payload in GET_CHART_BY_MONTH', payload)
      // 清空
      this.commit('siteInfoDetail/CLEAR_CHART_SERIES')
      // set yMax
      this.commit('siteInfoDetail/SET_YMAX_ACPALL', payload)

      // data push
      payload.data = payload.data.sort(function (a, b) {
        return a.month > b.month ? 1 : -1
      })
      payload.data.forEach((item) => {
        if (
          item.acp &&
          (item.acp !== undefined ||
            item.IRR !== undefined ||
            item.pr !== undefined ||
            item.PVTemp !== undefined)
        ) {
          const time = item.month
          const acp = item.acp !== null ? dbDataHandle(item.acp.all) : null // 發電量
          const IRR =
            typeof item.IRR === 'object' && item.IRR !== null
              ? dbDataHandle(item.IRR.avg)
              : null // 日照量
          // const PR = calculatePR(this.state.capacity, item.acp.all, IRR)
          const PR =
            item.pr !== null
              ? dbDataHandle(Math.floor(item.pr.all * 100))
              : null
          const PVTemp = item.PVTemp > 0 ? dbDataHandle(item.PVTemp) : null
          const revenue = dbDataHandle(acp * price, 0)
          state.siteInfoDetailChartOptions.xAxis[0].categories.push(time)
          state.siteInfoDetailChartOptions.series[0].data.push(acp)
          state.siteInfoDetailChartOptions.series[1].data.push(IRR)
          state.siteInfoDetailChartOptions.series[2].data.push(PR)
          state.siteInfoDetailChartOptions.series[3].data.push(PVTemp)
          if (state.showRevenue) {
            state.siteInfoDetailChartOptions.series[4].data.push(revenue)
          }
        }
      })

      // revenue Ymax
      const revenueDataMax = Math.max(
        ...state.siteInfoDetailChartOptions.series[4].data
      )
      state.siteInfoDetailChartOptions.yAxis[4].max =
        revenueDataMax === 0 ? 100 : null // auto calculated

      this.commit(
        'siteInfoDetail/GET_DOWNLOAD_DATA',
        state.siteInfoDetailChartOptions
      )
    }
  },
  GET_CHART_BY_YEAR (state, payload) {
    if (!payload?.success) {
      state.resChoose = '年歷史'
      state.resError = true
    } else {
      state.resError = false
      state.showPR = true

      // get price from store
      const info = mainStore.state.factoryInfo.filter(
        (f) => f.factoryId === router.history.current.query.fid
      )
      const unitPrice = info[0]?.informations.unitPrice
      let price = 0

      if (unitPrice && unitPrice !== '-') {
        price = unitPrice * 1
        state.showRevenue = true
      }

      // console.log('payload in GET_CHART_BY_YEAR', payload)
      // 清空
      this.commit('siteInfoDetail/CLEAR_CHART_SERIES')
      // set yMax
      this.commit('siteInfoDetail/SET_YMAX_ACPALL', payload)

      // data push
      payload.data = payload.data.sort(function (a, b) {
        return a.year > b.year ? 1 : -1
      })
      payload.data.forEach((item) => {
        const time = item.year
        const acp = dbDataHandle(item.acp.all) // 發電量
        const IRR =
          typeof item.IRR === 'object' && item.IRR !== null
            ? dbDataHandle(item.IRR.avg)
            : dbDataHandle(item.IRR) // 日照量
        const PVTemp = item.PVTemp > 0 ? dbDataHandle(item.PVTemp) : null
        // const PR = calculatePR(this.state.capacity, item.acp.all, IRR)
        const PR = dbDataHandle(Math.floor(item.pr.all * 100))
        const revenue = dbDataHandle(acp * price, 0)
        state.siteInfoDetailChartOptions.xAxis[0].categories.push(time)
        state.siteInfoDetailChartOptions.series[0].data.push(acp)
        state.siteInfoDetailChartOptions.series[1].data.push(IRR)
        state.siteInfoDetailChartOptions.series[2].data.push(PR)
        state.siteInfoDetailChartOptions.series[3].data.push(PVTemp)
        if (state.showRevenue) {
          state.siteInfoDetailChartOptions.series[4].data.push(revenue)
        }
      })

      // revenue Ymax
      const revenueDataMax = Math.max(
        ...state.siteInfoDetailChartOptions.series[4].data
      )
      state.siteInfoDetailChartOptions.yAxis[4].max =
        revenueDataMax === 0 ? 100 : null // auto calculated

      this.commit(
        'siteInfoDetail/GET_DOWNLOAD_DATA',
        state.siteInfoDetailChartOptions
      )
    }
  },
  GET_CHART_BY_INVS (state, payload) {
    if (!payload?.success) {
      state.resChoose = '變流器歷史'
      state.resError = true
    } else {
      state.resError = false
      state.showPR = false

      // get price from store
      const info = mainStore.state.factoryInfo.filter(
        (f) => f.factoryId === router.history.current.query.fid
      )
      const unitPrice = info[0]?.informations.unitPrice
      let price = 0

      if (unitPrice && unitPrice !== '-') {
        price = unitPrice * 1
        state.showRevenue = true
      }

      // console.log('payload in GET_CHART_BY_INVS', payload)
      // 清空
      this.commit('siteInfoDetail/CLEAR_CHART_SERIES')
      // set yMax
      this.commit('siteInfoDetail/SET_YMAX', payload)

      // data push
      payload.data = payload.data.sort(function (a, b) {
        return a.hhmm > b.hhmm ? 1 : -1
      })
      payload.data.forEach((item) => {
        const time = item.hhmm
        const acp = dbDataHandle(item.acp.all) // 發電量
        const IRR =
          typeof item.IRR === 'object' && item.IRR !== null
            ? dbDataHandle(item.IRR.avg)
            : dbDataHandle(item.IRR) // 日照量
        const PVTemp = item.PVTemp > 0 ? dbDataHandle(item.PVTemp) : null // 模組溫度
        const revenue = dbDataHandle(acp * price, 0)
        state.siteInfoDetailChartOptions.xAxis[0].categories.push(time)
        state.siteInfoDetailChartOptions.series[0].data.push(acp)
        state.siteInfoDetailChartOptions.series[1].data.push(IRR)
        state.siteInfoDetailChartOptions.series[3].data.push(PVTemp)
        if (state.showRevenue) {
          state.siteInfoDetailChartOptions.series[4].data.push(revenue)
        }
      })

      // revenue Ymax
      const revenueDataMax = Math.max(
        ...state.siteInfoDetailChartOptions.series[4].data
      )
      state.siteInfoDetailChartOptions.yAxis[4].max =
        revenueDataMax === 0 ? 100 : null // auto calculated

      this.commit(
        'siteInfoDetail/GET_DOWNLOAD_DATA',
        state.siteInfoDetailChartOptions
      )
    }
  },
  GET_DOWNLOAD_DATA (state, payload) {
    state.siteInfoDetailDownloadField = []
    state.siteInfoDetailDownloadData = []
    state.siteInfoDetailDownloadField = ['name']
    state.siteInfoDetailDownloadField =
      state.siteInfoDetailDownloadField.concat(
        state.siteInfoDetailChartOptions.xAxis[0].categories
      )
    payload.series.forEach((x) => {
      const obj = {
        name: x.name
      }
      state.siteInfoDetailDownloadData.push(obj)
    })
    payload.xAxis[0].categories.forEach((x, idx) => {
      state.siteInfoDetailDownloadData.forEach((y, yIdx) => {
        // 空一格 fix 年歷史 name 標題應位於最左側 (obj key 順序問題)
        y[`${x} `] = payload.series[yIdx].data[idx]
      })
    })
  },
  referenceChartOptions (state, payload) {
    Object.assign(state.siteInfoDetailChartOptions, payload)
  }
}

const actions = {
  increment (context) {
    console.log(context)
    context.commit('increment')
  },
  weather ({ commit }) {
    const params = {
      isHttps: process.env.NODE_ENV === 'development' ? 'http' : 'https',
      apikey: '618aaaa4fffcb24d5194539b1fb8acb1',
      unit: 'metric',
      lang: 'zh_TW',
      format: 'JSON',
      lon: '',
      lat: ''
    }
    if (router.history.current.query.siteName) {
      params.lon = state.weatherLonAndLat.lon
      params.lat = state.weatherLonAndLat.lat
    }
    const { isHttps, apikey, unit, lang, format, lon, lat } = params
    if (lon && lat) {
      axios
        .get(
          `${isHttps}://api.openweathermap.org/data/2.5/weather?apikey=${apikey}&units=${unit}&lang=${lang}&format=${format}&lon=${lon}&lat=${lat}
        `
        )
        .then((res) => {
          const isHttpsObj = { isHttps }
          Object.assign(res.data, isHttpsObj)
          commit('WEATHER', res.data)
        })
        .catch((err) => console.log('weather error: ', err))
    }
  },
  // 最近 30 天
  async getChartByRecentThirtyDays ({ commit }, payload) {
    const { fId } = payload
    await getRecentThirtyDaysApi(fId).then((data) =>
      commit('GET_CHART_BY_RECENT_30_DAYS', data?.data)
    )
  },
  // 時歷史
  async getChartByHour ({ commit }, payload) {
    const { todayDate, fId, siteName } = payload
    await getHoursApi(todayDate, fId, siteName).then((data) =>
      commit('GET_CHART_BY_HOUR', data?.data)
    )
  },
  // 日歷史
  async getChartByDay ({ commit }, payload) {
    const { thisMon, fId, siteName } = payload
    await getDaysApi(thisMon, fId, siteName).then((data) => {
      commit('GET_CHART_BY_DAY', data?.data)
    })
  },
  // 月歷史
  async getChartByMonth ({ commit }, payload) {
    const { thisYear, fId, siteName } = payload
    await getMonthApi(thisYear, fId, siteName).then((data) =>
      commit('GET_CHART_BY_MONTH', data?.data)
    )
  },
  // 年歷史
  async getChartByYear ({ commit }, payload) {
    const { fId, siteName } = payload
    await getYearApi(fId, siteName).then((data) =>
      commit('GET_CHART_BY_YEAR', data?.data)
    )
  },
  // 變流器歷史
  async getChartByInvs ({ commit }, payload) {
    const { date, invNum, fId, siteName } = payload
    await getInvsApi(date, invNum, fId, siteName).then((data) =>
      commit('GET_CHART_BY_INVS', data?.data)
    )
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
