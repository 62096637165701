import router from '../../router/index'
import store from '../../store/index'
import { getDeviceHistoryApi } from '../../api'
import { DateTime } from 'luxon'

const state = {
  tableField: [],
  tableData: [],
  selectOptions: []
}

const getters = {
  hasTableData: state => !!state.tableData.length
}

const mutations = {
  SET_TABLE_DATA (state, payload) {
    state.tableData = payload
  }
}

const actions = {
  handleTableField ({ state }) {
    state.tableField = [
      { key: '時間', sortable: true, label: '時間' },
      { key: '頻率(Hz)', sortable: true },
      { key: 'Vab 電壓 (V)', sortable: true },
      { key: 'Vbc 電壓 (V)', sortable: true },
      { key: 'Vca 電壓 (V)', sortable: true },
      { key: 'A相電流 (A)', sortable: true },
      { key: 'B相電流 (A)', sortable: true },
      { key: 'C相電流 (A)', sortable: true }
    ]
    const pvLen = router.history.current.query.pvLen
    for (let i = 1; i < parseInt(pvLen) + 1; i++) {
      const VField = {
        key: `PV${i}電壓 (V)`,
        sortable: true
      }
      const CField = {
        key: `PV${i}電流 (A)`,
        sortable: true
      }
      state.tableField.push(VField)
      state.tableField.push(CField)
    }
  },
  handleSelectOptions ({ state }) {
    const invLength = router.history.current.query.invLen
    const data = []
    for (let i = 1; i < parseInt(invLength) + 1; i++) {
      const option = {
        text: `INV${('0' + i).slice(-2)}`,
        value: i
      }
      data.push(option)
    }
    state.selectOptions = data
  },
  async fetchTableData ({ commit }, payload) {
    let siteName = router.history.current.query.siteName

    const isDemo = siteName.includes('展示案場')
    if (isDemo) {
      const demoIndex = siteName.split(' ')[1] - 1
      siteName = store.state.factoryInfo[demoIndex].factory
    }
    const dayStart = DateTime.fromJSDate(payload.tableQueryDate).toFormat(
      'yyyy-MM-dd'
    )
    payload.tableQueryDate.setDate(payload.tableQueryDate.getDate() + 1)
    const dayEnd = DateTime.fromJSDate(payload.tableQueryDate).toFormat(
      'yyyy-MM-dd'
    )
    payload.tableQueryDate.setDate(payload.tableQueryDate.getDate() - 1)
    const invNum = payload.tableQuerySelectNum - 1

    return getDeviceHistoryApi(
      router.history.current.query.fid,
      siteName,
      invNum,
      dayStart,
      dayEnd
    ).then((res) => {
      const tableData = []
      if (res.data.data?.length) {
        res.data.data.forEach((item) => {
          const pvLength = parseInt(router.history.current.query.pvLen)
          let dataFilter = 0
          let vFilter = 0
          let aFilter = 0
          let V = {}
          if (item.mpptv && item.mppta) {
            vFilter = item.mpptv?.reduce((a, b) => a + b)
            aFilter = item.mppta?.reduce((a, b) => a + b)
          } else {
            vFilter = item.pvv?.reduce((a, b) => a + b)
            aFilter = item.pva?.reduce((a, b) => a + b)
          }
          if (item.Vrs || item.Vst || item.Vrt) {
            V.ab = item.Vrs
            V.bc = item.Vst
            V.ca = item.Vrt
          } else if (item.Vrn || item.Vsn || item.Vtn) {
            V.ab = item.Vrn
            V.bc = item.Vsn
            V.ca = item.Vtn
          }
          const data = {
            時間: item.timestamp?.split('T')[1].substr(0, 5),
            '頻率(Hz)': item.freq,
            'Vab 電壓 (V)': V.ab,
            'Vbc 電壓 (V)': V.bc,
            'Vca 電壓 (V)': V.ca,
            'A相電流 (A)': item.Rc,
            'B相電流 (A)': item.Sc,
            'C相電流 (A)': item.Tc,
            _cellVariants: {}
          }
          for (let i = 1; i < Object.values(data).length; i++) {
            if (typeof Object.values(data)[i] === 'string') {
              Object.values(data)[i] = Object.values(data)[i] * 1
            }
            dataFilter += Object.values(data)[i]
          }
          for (let i = 0; i < pvLength; i++) {
            let a = `PV${i + 1}電流 (A)`
            let v = `PV${i + 1}電壓 (V)`
            if (item.mpptv || item.mpptv) {
              const Obj = {
                [a]: item.mppta?.[i],
                [v]: item.mpptv?.[i]
              }
              Object.assign(data, Obj)
            } else if (item.pva || item.pvv) {
              const Obj = {
                [a]: item.pva?.[i],
                [v]: item.pvv?.[i]
              }
              Object.assign(data, Obj)
            }
          }
          if (vFilter !== 0 || aFilter !== 0 || dataFilter !== 0) {
            tableData.push(data)
          }
        })
      }

      tableData.forEach((x) => {
        const arr = []
        for (const [key, value] of Object.entries(x)) {
          const timeToNumber = Number(x['時間'].split(':')[0])
          if (timeToNumber >= 5 && timeToNumber < 19 && value === 0) {
            arr.push(key)
          }
        }
        arr.forEach((y) => {
          x._cellVariants[y] = 'danger'
        })
      })

      commit('SET_TABLE_DATA', tableData)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
