import router from '../../router/index'

const state = {
  invSelectOptions: []
}

const mutations = {
  updateSiteInfoInvSelectOptions (state) {
    state.invSelectOptions = []

    const invNum = Number(router.history.current.query.invLen)

    state.invSelectOptions = [...Array(invNum)].map((_item, idx) => ({
      value: idx + 1,
      text: `INV${(idx + 1).toString().padStart(2, '0')}`
    }))
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
