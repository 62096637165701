import router from '../../router/index'
const state = {
  deviceStatusChartOptions: {
    chart: {
      backgroundColor: 'white',
      height: 300,
      marginTop: 50
    },
    title: '',
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      margin: 30
    },
    xAxis: {
      categories: [],
      crosshair: false
    },
    yAxis: [
      {
        title: '', // 不顯示 y values
        // title: {
        //   text: '累計發電量 (kWh)'
        // },
        labels: {
          enabled: false // 不顯示 y 刻度
        },
        min: 0,
        opposite: true
      },
      {
        title: '',
        // title: {
        //   text: '今日發電量 (kWh)'
        // },
        labels: {
          enabled: false
        },
        min: 0
      },
      {
        title: '',
        // title: {
        //   text: '交流/直流發電功率 (kW)'
        // },
        labels: {
          enabled: false
        },
        gridLineWidth: 0,
        min: 0
      }
    ],
    plotOptions: {
      series: {
        // threshold: 1000,
        // stacking: 'normal',
        dataLabels: {
          enabled: true
        },
        softThreshold: false
      },
      column: {
        dataLabels: {
          enabled: true
          // format: '{ point.y : .1f }'
        }
      }
    },
    series: [
      {
        data: [],
        type: 'column',
        name: '累積發電量',
        yAxis: 0,
        tooltip: {
          valueSuffix: ' (kWh)'
        }
      },
      {
        data: [],
        type: 'line',
        name: '今日發電量',
        yAxis: 1,
        tooltip: {
          valueSuffix: ' (kWh)'
        }
      },
      {
        data: [],
        type: 'line',
        name: '交流發電功率',
        yAxis: 2,
        tooltip: {
          valueSuffix: ' (kW)'
        }
      },
      {
        data: [],
        type: 'line',
        name: '直流發電功率',
        yAxis: 2,
        tooltip: {
          valueSuffix: ' (kW)'
        }
      }
    ],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            y: 0
          }
        }
      }]
    }
  },
  tableField: [],
  tableData: [],
  dataTime: ''
}
const actions = {
  handleTableField ({ state }, pvOnly) {
    state.tableField = []
    if (pvOnly) {
      state.tableField = [
        { key: 'INV 名稱', sortable: true, thClass: 'thClass', label: 'INV 名稱', stickyColumn: true, isRowHeader: true },
        { key: '連線狀態', sortable: true, thClass: 'thClass', label: '連線狀態' }
      ]
    } else {
      state.tableField = [
        { key: 'INV 名稱', sortable: true, thClass: 'thClass', label: 'INV 名稱', stickyColumn: true, isRowHeader: true },
        { key: '連線狀態', sortable: true, thClass: 'thClass', label: '連線狀態' },
        { key: '累積發電量 (kWh)', sortable: true, thClass: 'thClass', label: '累積發電量 (kWh)' },
        { key: '今日發電量 (kWh)', sortable: true, thClass: 'thClass', label: '今日發電量 (kWh)' },
        { key: '直流發電功率 (kW)', sortable: true, thClass: 'thClass', label: '直流發電功率 (kW)' },
        { key: '交流發電功率 (kW)', sortable: true, thClass: 'thClass', label: '交流發電功率 (kW)' },
        { key: 'INV效率 (%)', sortable: true, thClass: 'thClass', label: 'INV效率 (%)' },
        { key: '頻率(Hz)', sortable: true, thClass: 'thClass', label: '頻率(Hz)' },
        { key: 'Vab 電壓 (V)', sortable: true, thClass: 'thClass', label: 'Vab 電壓 (V)' },
        { key: 'Vbc 電壓 (V)', sortable: true, thClass: 'thClass', label: 'Vbc 電壓 (V)' },
        { key: 'Vca 電壓 (V)', sortable: true, thClass: 'thClass', label: 'Vca 電壓 (V)' },
        { key: 'A相電流 (A)', sortable: true, thClass: 'thClass', label: 'A相電流 (A)' },
        { key: 'B相電流 (A)', sortable: true, thClass: 'thClass', label: 'B相電流 (A)' },
        { key: 'C相電流 (A)', sortable: true, thClass: 'thClass', label: 'C相電流 (A)' }
      ]
    }
    const pvLen = router.history.current.query.pvLen * 1
    for (let i = 1; i < pvLen + 1; i++) {
      const VField = {
        key: `PV${i}電壓 (V)`,
        label: `PV${i}電壓 (V)`,
        sortable: true
      }
      const CField = {
        key: `PV${i}電流 (A)`,
        label: `PV${i}電流 (A)`,
        sortable: true
      }
      if (i <= pvLen) {
        state.tableField.push(VField)
        state.tableField.push(CField)
      }
    }
  },
  xAxisCategoriesHandle ({ state }) {
    state.deviceStatusChartOptions.xAxis.categories = []
    const xAxisLen = router.history.current.query.invLen * 1
    for (let i = 0; i < xAxisLen; i++) {
      const xAxis = `INV${('0' + (i + 1)).slice(-2)}`
      state.deviceStatusChartOptions.xAxis.categories.push(xAxis)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions
}
