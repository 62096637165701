<template>
  <div id="app">
    <!-- navbar -->
    <b-navbar
      class="b-navbar"
      toggleable="true"
      v-if="
        currentName !== 'login' &&
        currentName !== 'tvscreen' &&
        currentName !== 'tvwalllooping'
      "
    >
      <b-navbar-brand
        :to="'/' + lang + '/realtime'"
        class="navBar"
        style="
          color: var(--acmeLogo);
          font-weight: bold;
          font-size: 21px;
          position: relative;
          top: -10px;
          display: flex;
          align-items: center;
        "
      >
        <img src="./assets/acme-logo.png" class="acme-logo" alt="" />
        {{ logoText }}
      </b-navbar-brand>
      <div class="dropdown-custom">
        <div class="dropdown-menu-custom">
          <div
            class="dropdown-menu-custom-btn"
            ref="globeBtn"
            @click="darkMode"
            v-if="!chageMode"
          >
            <i class="fas fa-adjust"></i>
          </div>
          <div
            class="dropdown-menu-custom-btn"
            ref="globeBtn"
            @click="lightMode"
            v-if="chageMode"
          >
            <i class="fas fa-adjust"></i>
          </div>
        </div>
        <div class="dropdown-menu-custom" @mouseleave="mouseleave">
          <div
            class="dropdown-menu-custom-btn"
            @click="langSelection = !langSelection"
            ref="globeBtn"
          >
            <i class="fas fa-globe-asia"></i>
          </div>
          <transition>
            <div
              class="dropdown-menu-custom-content lang-dropdown-phone-position"
              v-if="langSelection"
            >
              <div
                class="twese"
                :class="{ 'lang-active': selected === 1 }"
                @click="chooseLanguage(1)"
              >
                繁體中文
              </div>
              <div
                class="english"
                :class="{ 'lang-active': selected === 0 }"
                @click="chooseLanguage(0)"
              >
                English
              </div>
            </div>
          </transition>
        </div>
        <div class="dropdown-menu-custom" @mouseleave="mouseleave">
          <div
            class="dropdown-menu-custom-btn"
            @click="factorySelection = !factorySelection"
            ref="solarBtn"
          >
            <i class="fas fa-bars"></i>
          </div>
          <transition>
            <div class="dropdown-menu-custom-content" v-if="factorySelection">
              <div
                :class="{
                  'lang-active':
                    currentName === 'realtime' ||
                    roleComponentMenu.includes(currentName),
                }"
                @click="goToPageFromDropdown('realtime')"
              >
                <span><i class="fas fa-home"></i></span
                >{{ $t("sideBarItems.realTime") }}
              </div>

              <div
                :class="{ 'lang-active': currentName === 'foreignfactories' }"
                @click="goToPageFromDropdown('foreignfactories')"
                v-if="rolePermissionArray.includes('foreignfactories')"
              >
                <span><i class="fas fa-poll"></i></span>外來監控資訊
              </div>

              <div v-if="hasRoleAdmin" @click="goToInfoDesignPage">
                <span><i class="fas fa-file-upload"></i></span>案場資訊輸入
              </div>

              <div
                :class="{ 'lang-active': currentName === 'factorystatus' }"
                @click="goToPageFromDropdown('factorystatus')"
                v-if="rolePermissionArray.includes('factorystatus')"
              >
                <span><i class="fas fa-exclamation-circle"></i></span>案場狀態
              </div>

              <div
                :class="{ 'lang-active': currentName === 'historyalarms' }"
                @click="goToPageFromDropdown('historyalarms')"
                v-if="rolePermissionArray.includes('historyalarms')"
              >
                <span><i class="fa fa-bell"></i></span>告警事件
              </div>

              <div
                :class="{ 'lang-active': currentName === 'usermanage' }"
                @click="goToPageFromDropdown('usermanage')"
                v-if="rolePermissionArray.includes('usermanage')"
              >
                <span><i class="fa fa-user"></i></span>使用者管理
              </div>

              <div
                :class="{ 'lang-active': currentName === 'groupmanage' }"
                @click="goToPageFromDropdown('groupmanage')"
                v-if="rolePermissionArray.includes('groupmanage')"
              >
                <span><i class="fa fa-users"></i></span>群組管理
              </div>

              <div
                :class="{ 'lang-active': currentName === 'emailsend' }"
                @click="goToPageFromDropdown('emailsend')"
                v-if="rolePermissionArray.includes('emailsend')"
              >
                <span><i class="fas fa-envelope"></i></span>信件補發
              </div>

              <div
                :class="{
                  'lang-active': currentName === 'multiplefactoryanalysis',
                }"
                @click="goToPageFromDropdown('multiplefactoryanalysis')"
                v-if="rolePermissionArray.includes('multiplefactoryanalysis')"
              >
                <span><i class="fas fa-chart-line"></i></span>跨案場分析
              </div>

              <div
                :class="{ 'lang-active': currentName === 'rawdatadownload' }"
                @click="goToPageFromDropdown('rawdatadownload')"
                v-if="rolePermissionArray.includes('rawdatadownload')"
              >
                <span><i class="fas fa-solid fa-download"></i></span
                >原始資料下載
              </div>

              <div
                :class="{ 'lang-active': currentName === 'raanalysis' }"
                @click="goToPageFromDropdown('raanalysis')"
                v-if="rolePermissionArray.includes('raanalysis')"
              >
                <span><i class="fas fa-solid fa-table"></i></span>RA 資料分析
              </div>

              <div
                :class="{ 'lang-active': currentName === 'kpireport' }"
                @click="goToPageFromDropdown('kpireport')"
                v-if="rolePermissionArray.includes('kpireport')"
              >
                <span><i class="far fa-file-alt"></i></span>KPI 每月報告
              </div>

              <div
                :class="{ 'lang-active': currentName === 'sortsetting' }"
                @click="goToPageFromDropdown('sortsetting')"
                v-if="rolePermissionArray.includes('sortsetting')"
              >
                <span><i class="fas fa-sort-amount-up"></i></span>案場排序
              </div>

              <div
                :class="{ 'lang-active': currentName === 'fuqiangxindemo' }"
                @click="goToPageFromDropdown('fuqiangxindemo')"
                v-if="rolePermissionArray.includes('fuqiangxindemo')"
              >
                <span><i class="fas fa-tachometer-alt"></i></span>富強鑫展示畫面
              </div>

              <div
                :class="{ 'lang-active': currentName === 'tvwalllooping' }"
                @click="goToPageFromDropdown('tvwalllooping')"
                v-if="rolePermissionArray.includes('tvwalllooping')"
              >
                <span><i class="fas fa-desktop"></i></span>電視牆輪播
              </div>
            </div>
          </transition>
        </div>
        <div
          class="login-button-style"
          v-b-tooltip.hover.bottom="'點擊登出'"
          @click="signOut"
        >
          <i class="fa fa-user" style="margin-right: 5px" aria-hidden="true"></i
          ><span class="font-weight-bold">{{
            userDetail.displayName || "使用者"
          }}</span>
        </div>
      </div>
    </b-navbar>
    <div class="sidebar-view-wrapper">
      <div
        class="side-bar"
        :class="{ 'sidebar-expanded': expanded }"
        ref="sidebar"
        v-if="
          currentName !== 'login' &&
          currentName !== 'tvscreen' &&
          currentName !== 'tvwalllooping'
        "
      >
        <div class="expand-sidebar">
          <div class="expand-sidebar-wrapper" @click="expanded = !expanded">
            <i class="fas fa-exchange-alt"></i>
          </div>
        </div>

        <ul class="side-bar-ul">
          <li
            class="side-bar-li"
            v-b-tooltip.window.hover.right="$t('sideBarItems.realTime')"
            :class="{
              'sidebar-selected':
                currentName === 'realtime' ||
                roleComponentMenu.includes(currentName),
            }"
          >
            <router-link :to="'/' + lang + '/realtime'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-home"></i></div>
                <span v-if="expanded">{{ $t("sideBarItems.realTime") }}</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('foreignfactories')"
            v-show="true"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'外來監控資訊'"
            :class="{ 'sidebar-selected': currentName === 'foreignfactories' }"
          >
            <router-link :to="'/' + lang + '/foreignfactories'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-poll"></i></div>
                <span v-if="expanded">外來監控資訊</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="hasRoleAdmin"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'案場資訊輸入'"
            @click="goToInfoDesignPage"
          >
            <div class="side-item-wrapper">
              <div><i class="fas fa-file-upload"></i></div>
              <span v-if="expanded">案場資訊輸入</span>
            </div>
          </li>

          <li
            v-if="rolePermissionArray.includes('factorystatus')"
            v-show="true"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'案場狀態'"
            :class="{ 'sidebar-selected': currentName === 'factorystatus' }"
          >
            <router-link :to="'/' + lang + '/factoryStatus'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-exclamation-circle"></i></div>
                <span v-if="expanded">案場狀態</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('historyalarms')"
            v-show="true"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'告警事件'"
            :class="{ 'sidebar-selected': currentName === 'historyalarms' }"
          >
            <router-link :to="'/' + lang + '/historyalarms'">
              <div class="side-item-wrapper">
                <div><i class="fa fa-bell"></i></div>
                <span v-if="expanded">告警事件</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('usermanage')"
            v-show="true"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'使用者管理'"
            :class="{ 'sidebar-selected': currentName === 'usermanage' }"
          >
            <router-link :to="'/' + lang + '/usermanage'">
              <div class="side-item-wrapper">
                <div><i class="fa fa-user"></i></div>
                <span v-if="expanded">使用者管理</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('groupmanage')"
            v-show="true"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'群組管理'"
            :class="{ 'sidebar-selected': currentName === 'groupmanage' }"
          >
            <router-link :to="'/' + lang + '/groupmanage'">
              <div class="side-item-wrapper">
                <div><i class="fa fa-users"></i></div>
                <span v-if="expanded">群組管理</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('emailsend')"
            v-show="true"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'信件補發'"
            :class="{ 'sidebar-selected': currentName === 'emailsend' }"
          >
            <router-link :to="'/' + lang + '/emailsend'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-envelope"></i></div>
                <span v-if="expanded">信件補發</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('multiplefactoryanalysis')"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'跨案場分析'"
            :class="{
              'sidebar-selected': currentName === 'multiplefactoryanalysis',
            }"
          >
            <router-link :to="'/' + lang + '/multiplefactoryanalysis'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-chart-line"></i></div>
                <span v-if="expanded">跨案場分析</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('rawdatadownload')"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'原始資料下載'"
            :class="{ 'sidebar-selected': currentName === 'rawdatadownload' }"
          >
            <router-link :to="'/' + lang + '/rawdatadownload'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-solid fa-download"></i></div>
                <span v-if="expanded">原始資料下載</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('raanalysis')"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'RA 資料分析'"
            :class="{ 'sidebar-selected': currentName === 'raanalysis' }"
          >
            <router-link :to="'/' + lang + '/raanalysis'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-solid fa-table"></i></div>
                <span v-if="expanded">RA 資料分析</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('kpireport')"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'KPI 每月報告'"
            :class="{ 'sidebar-selected': currentName === 'kpireport' }"
          >
            <router-link :to="'/' + lang + '/kpireport'">
              <div class="side-item-wrapper">
                <div><i class="far fa-file-alt"></i></div>
                <span v-if="expanded">KPI 每月報告</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('sortsetting')"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'案場排序'"
            :class="{ 'sidebar-selected': currentName === 'sortsetting' }"
          >
            <router-link :to="'/' + lang + '/sortsetting'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-sort-amount-up"></i></div>
                <span v-if="expanded">案場排序</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('fuqiangxindemo')"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'富強鑫展示畫面'"
            :class="{ 'sidebar-selected': currentName === 'fuqiangxindemo' }"
          >
            <router-link :to="'/' + lang + '/fuqiangxindemo'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-tachometer-alt"></i></div>
                <span v-if="expanded">富強鑫展示畫面</span>
              </div>
            </router-link>
          </li>

          <li
            v-if="rolePermissionArray.includes('tvwalllooping')"
            class="side-bar-li"
            v-b-tooltip.window.hover.right="'電視牆輪播'"
            :class="{ 'sidebar-selected': currentName === 'tvwalllooping' }"
          >
            <router-link :to="'/' + lang + '/tvwalllooping'">
              <div class="side-item-wrapper">
                <div><i class="fas fa-desktop"></i></div>
                <span v-if="expanded">電視牆輪播</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>

      <keep-alive v-if="isLoggedIn">
        <router-view
          v-if="$route.meta.keepAlive"
          class="router-view"
        ></router-view>
      </keep-alive>
      <router-view
        v-if="!$route.meta.keepAlive || !isLoggedIn"
        class="router-view"
      ></router-view>
    </div>
  </div>
</template>

<script>
import { BNavbar, BNavbarBrand } from 'bootstrap-vue'
import i18n from './lang/lang'
import { mapState, mapMutations, mapActions } from 'vuex'
import { darkmode } from './store/function/common/darkMode'
import { logoutApi } from './api'
export default {
  name: 'app',
  components: {
    BNavbar,
    BNavbarBrand
  },
  data () {
    return {
      logoText: '雲端監控中心',
      selected: 1, // 語系的選中
      factorySelected: 0, // 摺疊案場的選中
      expanded: false,
      toggler: false,
      langSelection: false,
      factorySelection: false,
      isGotoTopButton: false,
      chageMode: false,
      windowScrollTopValue: 0,
      isFocusing: false,
      timer: undefined,
      isLoggedIn: false, // 判斷是否已登入 -> keep-alive
      ws: undefined // websocket
    }
  },
  computed: {
    ...mapState([
      'userDetail',
      'msalInstance',
      'currentName',
      'rolePermissionArray',
      'roleComponentMenu',
      'hasRoleAdmin'
    ]),
    lang () {
      let currentLangInUri = this.selected === 1 ? 'tw' : 'en'
      i18n.locale = currentLangInUri
      return currentLangInUri || 'tw'
    }
  },
  watch: {
    // 非已登入狀態 頁面不緩存
    $route (_to, _from) {
      // if the route changes...
      let token = localStorage.getItem('userDetail') || ''
      if (token) {
        this.isLoggedIn = true
      } else {
        this.isLoggedIn = false
      }
    },
    isLoggedIn (_newVal, _preVal) {
      if (this.isLoggedIn) {
        this.startWebsocket()
      } else {
        this.ws.close()
      }
    }
  },
  methods: {
    ...mapMutations([
      'CHANGE_DARK_MODE',
      'CHANGE_LIGHT_MODE',
      'CHANGE_REALTIME_RELOAD'
    ]),
    ...mapActions(['setTelemetryData']),
    chooseLanguage (select) {
      i18n.locale = this.selected === 1 ? 'tw' : 'en'
      if (this.selected === select) return

      this.selected = select
    },
    goToPageFromDropdown (str) {
      this.$router
        .push({ path: `/${i18n.locale}/${str}` })
        .catch((err) => console.log('router push has error', err))
      // i18n.locale = 'en'
    },
    startWebsocket () {
      const socketOrigin =
        location.hostname === 'localhost'
          ? 'ws://localhost:3000'
          : `wss://${location.hostname}`
      if (
        location.pathname !== '/tvscreen' ||
        location.pathname !== '/tw/tvwalllooping'
      ) {
        this.ws = new WebSocket(socketOrigin)
        this.ws.onopen = () => {
          console.log('open connection')
        }
        this.ws.onclose = () => {
          console.log('close connection')
        }
        this.ws.onmessage = ({ data }) => {
          try {
            // if (this.isFocusing) {
            const message = JSON.parse(data)
            if (message.factoryName) {
              // console.log(message)
              this.setTelemetryData(message)
            }
            // }
          } catch (error) {
            console.log(error)
          }
        }
        window.onmousemove = (ev) => {
          if (ev.isTrusted) {
            this.isFocusing = true
            this.timer = setTimeout(() => {
              this.isFocusing = false
            }, 60 * 1000)
          }
        }
      }
    },
    async signOut () {
      await logoutApi().then(() => {
        localStorage.removeItem('userDetail')
        this.CHANGE_REALTIME_RELOAD(true)
        this.$router
          .push({ path: `/${i18n.locale}/login` })
          .catch((err) => console.log('router push has error', err))
      })
    },
    mouseleave () {
      if (this.langSelection) {
        this.langSelection = !this.langSelection
      }
      if (this.factorySelection) {
        this.factorySelection = !this.factorySelection
      }
    },
    darkMode () {
      darkmode()
      this.CHANGE_DARK_MODE()
      this.chageMode = !this.chageMode
    },
    lightMode () {
      const set = document.documentElement.style
      let styleArray = [
        '--acmeNavbar',
        '--sidebar',
        '--appBackground',
        '--normaltextcolor',
        '--icon',
        '--acmeTableTitle',
        '--acmecelltitle',
        '--acmeTabledata',
        '--acmePageTitle',
        '--acmeLogo',
        '--styleButton',
        '--linearButtonAndTitle',
        '--borderColor',
        '--siteinfoBackground',
        '--barItemBackground',
        '--siteInfoDetailBorder',
        '--infoCategoryBackground',
        '--infoCategoryBefore',
        '--siteinfoKeys',
        '--barItem',
        '--barItemClicked',
        '--chooseButton',
        '--titleLinear',
        '--borderBoxShow'
      ]
      for (let i = 0; i < styleArray.length; i++) {
        set.removeProperty(styleArray[i])
      }
      this.CHANGE_LIGHT_MODE()
      this.chageMode = !this.chageMode
    },
    goToInfoDesignPage () {
      window.open('https://factoryinfo.azurewebsites.net/')
    }
  },
  async created () {
    if (location.hostname === 'acmepointes-dev.azurewebsites.net') {
      this.logoText = '雲端監控中心-測試機'
      return
    }

    if (
      location.protocol === 'http:' &&
      location.hostname === 'om.acmepointes.com'
    ) {
      location.assign('https://om.acmepointes.com')
    }
  }
}
</script>
<style lang="scss">
// @import '~bootstrap-vue/src/components/navbar'
@import "./assets/scss/App.scss";
</style>
