<template>
  <center>
    <div style="margin-bottom:10px">
      <img src="../assets/acme-logo.png">
    </div>
    <h1>找不到頁面!</h1>
    <p>
      <a href="/">返回首頁?</a>
    </p>
  </center>
</template>

<script>

export default {
  name: 'NotFound'
}

</script>

<style scoped>

  center {
    margin: 15vw;
  }

  h1 {
    /* color: var(--border); */
    font-size: 2em;
  }

</style>
